<template>
    <div class="Loading">
        <svg height="64" viewBox="0 0 64 64" width="64" xmlns="http://www.w3.org/2000/svg">
            <g fill-rule="evenodd">
                <path class="a" :style="fill" d="m29 16.2807916v-13.2807916c0-1.65685425 1.3431458-3 3-3s3 1.34314575 3 3v13.2807916c-.9717523-.1843349-1.9746118-.2807916-3-.2807916s-2.0282477.0964567-3 .2807916z"/>
                <path class="b" :style="fill" d="m26.7377404 16.885499c-1.9252708.6702284-3.683121 1.6979269-5.1940671 3.0036119l-6.6417495-11.50384761c-.8284271-1.43487787-.3368017-3.26964909 1.0980762-4.09807621 1.4348779-.82842713 3.2696491-.33680166 4.0980762 1.09807621z"/>
                <path class="c" :style="fill" d="m19.8891109 21.5436733c-1.305685 1.5109461-2.3333835 3.2687963-3.0036119 5.1940671l-11.50023571-6.6396642c-1.43487787-.8284271-1.92650334-2.6631983-1.09807621-4.0980762.82842712-1.4348779 2.66319834-1.9265033 4.09807621-1.0980762z"/>
                <path class="d" :style="fill" d="m16.2807916 29c-.1843349.9717523-.2807916 1.9746118-.2807916 3s.0964567 2.0282477.2807916 3h-13.2807916c-1.65685425 0-3-1.3431458-3-3s1.34314575-3 3-3z"/>
                <path class="e" :style="fill" d="m16.885499 37.2622596c.6702284 1.9252708 1.6979269 3.683121 3.0036119 5.1940671l-11.50384761 6.6417495c-1.43487787.8284271-3.26964909.3368017-4.09807621-1.0980762-.82842713-1.4348779-.33680166-3.2696491 1.09807621-4.0980762z"/>
                <path class="f" :style="fill" d="m21.5436733 44.1108891c1.5109461 1.305685 3.2687963 2.3333835 5.1940671 3.0036119l-6.6396642 11.5002357c-.8284271 1.4348779-2.6631983 1.9265033-4.0980762 1.0980762s-1.9265033-2.6631983-1.0980762-4.0980762z"/>
                <path class="g" :style="fill" d="m29 47.7192084c.9717523.1843349 1.9746118.2807916 3 .2807916s2.0282477-.0964567 3-.2807916v13.2807916c0 1.6568542-1.3431458 3-3 3s-3-1.3431458-3-3z"/>
                <path class="h" :style="fill" d="m42.4563267 44.1108891 6.6417495 11.5038476c.8284271 1.4348779.3368017 3.2696491-1.0980762 4.0980762s-3.2696491.3368017-4.0980762-1.0980762l-6.6396642-11.5002357c1.9252708-.6702284 3.683121-1.6979269 5.1940671-3.0036119z"/>
                <path class="j" :style="fill" d="m47.7192084 29h13.2807916c1.6568542 0 3 1.3431458 3 3s-1.3431458 3-3 3h-13.2807916c.1843349-.9717523.2807916-1.9746118.2807916-3s-.0964567-2.0282477-.2807916-3z"/>
                <path class="i" :style="fill" d="m47.114501 37.2622596 11.5002357 6.6396642c1.4348779.8284271 1.9265033 2.6631983 1.0980762 4.0980762s-2.6631983 1.9265033-4.0980762 1.0980762l-11.5038476-6.6417495c1.305685-1.5109461 2.3333835-3.2687963 3.0036119-5.1940671z"/>
                <path class="k" :style="fill" d="m44.1108891 21.5436733 11.5038476-6.6417495c1.4348779-.8284271 3.2696491-.3368017 4.0980762 1.0980762s.3368017 3.2696491-1.0980762 4.0980762l-11.5002357 6.6396642c-.6702284-1.9252708-1.6979269-3.683121-3.0036119-5.1940671z"/>
                <path class="l" :style="fill" d="m37.2622596 16.885499 6.6396642-11.50023571c.8284271-1.43487787 2.6631983-1.92650334 4.0980762-1.09807621 1.4348779.82842712 1.9265033 2.66319834 1.0980762 4.09807621l-6.6417495 11.50384761c-1.5109461-1.305685-3.2687963-2.3333835-5.1940671-3.0036119z"/>
            </g>
        </svg> 
    </div>
</template>

<style>
.Loading{position:absolute;top:50%;left:50%;margin-top:-16px;margin-left: -16px;}.Loading,.Loading svg{width:32px;height:32px}.Loading svg path{opacity:.2;-webkit-animation:loading .77s 0s infinite;animation:loading .77s 0s infinite;}.Loading .a{-webkit-animation-delay:loading .77s infinite 0s;animation-delay:loading .77s infinite 0s}.Loading .b{-webkit-animation-delay:-.07s;animation-delay:-.07s}.Loading .c{-webkit-animation-delay:-.14s;animation-delay:-.14s}.Loading .d{-webkit-animation-delay:-.21s;animation-delay:-.21s}.Loading .e{-webkit-animation-delay:-.28s;animation-delay:-.28s}.Loading .f{-webkit-animation-delay:-.35s;animation-delay:-.35s}.Loading .g{-webkit-animation-delay:-.42s;animation-delay:-.42s}.Loading .h{-webkit-animation-delay:-.49s;animation-delay:-.49s}.Loading .i{-webkit-animation-delay:-.56s;animation-delay:-.56s}.Loading .j{-webkit-animation-delay:-.63s;animation-delay:-.63s}.Loading .k{-webkit-animation-delay:-.7s;animation-delay:-.7s}.Loading .l{-webkit-animation-delay:-.77s;animation-delay:-.77s}@-webkit-keyframes loading{0%{opacity:.25}10%{opacity:1}to{opacity:.25}}@keyframes loading{0%{opacity:.25}10%{opacity:1}to{opacity:.25}}
</style>

<script>
export default {
    computed: {
        fill() {
            return 'fill: '+ this.color;
        }
    }, 

    props: {
        color: {
            type: String,
            default: '#fff'
        }
    }
}
</script>